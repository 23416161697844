import React, { Component } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { getUserName, getUserEmail, getLoginType } from '../service/http-service';
import swal from 'sweetalert2';
import { setUserB64, postMethod } from '../service/http-service';
import { useToasts } from 'react-toast-notifications';
import { useHistory } from "react-router";

const Navbar = () => {

  const userName = getUserName() ? getUserName() : '';
  const userEmail = getUserEmail() ? getUserEmail() : '';
  const userType = getLoginType() ? getLoginType() : '';
  const { addToast } = useToasts();
  const history = useHistory();

  /*=================================FILE_FUNCATION===============================*/

  const toggleOffcanvas = () => {
    document.querySelector('.sidebar-offcanvas').classList.toggle('active');
  }
  const toggleRightSidebar = () => {
    document.querySelector('.right-sidebar').classList.toggle('open');
  }

  const notifyFailed = (error) => {
    let text = ''
    if (!error) {
      text = 'Error in logout';
    } else if (error.constructor == Array) {
      text = error[0].message ? error[0].message : 'ERROR'
    } else {
      text = error;
    }
    addToast(text, {
      appearance: 'error',
      autoDismiss: true,
    });
  }

  const confirmLogout = (e) => {
    e.preventDefault();
    return new swal({
      text: 'Do you want to logout ?',
      allowOutsideClick: false,
      allowEscapeKey: false,
      closeOnClickOutside: false,
      showCancelButton: true,
      customClass: 'logout-modal',
      buttons:
      {
        confirm: {
          text: "Yes",
          visible: true,
          closeModal: true,
          className: 'sweet-btn'
        }
      }
    }).then((confirm) => {
      if (confirm.isConfirmed) {
        openModal('Please wait system is logging out...');
      }
    });
  }

  const gotoUserProfile = (e) => {
    e.preventDefault();
    history.push('/user-profile');
  }

  const gotoChangePswd = (e) => {
    e.preventDefault();
    history.push('/change-password');
  }

  const openModal = (msg) => {
    swal.fire({
      html: msg,
      customClass: 'logout-modal',
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        swal.showLoading()
      }
    });
    postMethod('/api/users/signout', {}, 'post')
      .then((res) => {
        let errorMsg = '';
        swal.close();
        if (
          res && res.data
        ) {
          if (res.data.responseCode == "201") {
            // Success
            window.sessionStorage.clear();
            setUserB64(null);
            history.push('/login');
          } else if (res.data.responseCode === "400") {
            // Error
            errorMsg = res.data.errors || 'Error in log out';
            notifyFailed(errorMsg);
          } else {
            // Unknown Error
            errorMsg = 'Unknown Error in log out';
            notifyFailed(errorMsg);
          }
        } else {
          errorMsg = 'Unknown Error';
          notifyFailed(errorMsg);

        }
      }).catch(e => {
        swal.close();
        notifyFailed('Internal Server Error');
      });
  }

  /*============================================================*/

  return (
    <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
      <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
        <Link
          className="navbar-brand brand-logo"
          to="/dashboard">
          <img src={require('../../assets/images/smartInsure_logo.png')} alt="logo" />
        </Link>
        <Link className="navbar-brand brand-logo-mini d-inline-block d-lg-none" to="/dashboard"><img src={require('../../assets/images/logo-mini.svg')} alt="logo" /></Link>
        <button className="navbar-toggler navbar-toggler align-self-center" type="button" onClick={() => document.body.classList.toggle('sidebar-icon-only')}>
          <span className="icon-menu"></span>
        </button>
      </div>
      <div className="navbar-menu-wrapper d-flex align-items-center">
        <h5 className="mb-0 font-weight-medium  d-xl-flex">
          <Trans>Welcome SMART INSURE</Trans>
        </h5>
        <ul className="navbar-nav navbar-nav-right">
          <li className="nav-item nav-profile d-sm-flex">
            <Dropdown alignRight>
              <Dropdown.Toggle className="nav-link">
                <div className="nav-profile-img">
                  {userType && userType == 'Admin' ?
                    <img src={require("../../assets/images/Mahmoud.jpg")} alt="user" />
                    : <img src={require("../../assets/images/blank-profile.png")} alt="user" />

                  }
                  <span className="availability-status online"></span>
                </div>
                <div className="nav-profile-text">
                  <p className="mb-1 text-black"><Trans>{userName}</Trans></p>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="navbar-dropdown navbar-pop-up">
                {/* <Dropdown.Item href="!#" onClick={evt => evt.preventDefault()}>
                  <div class="dropdown-header text-center">
                    { userType && userType == 'Admin' &&
                      <img class="admin-img rounded-circle" src={require("../../assets/images/Mahmoud.jpg")} alt="user" />}
                    <p class="mb-1 mt-3">{userName}</p>
                    <p class="font-weight-light text-muted mb-0"><Trans>{userEmail}</Trans></p>
                  </div>
                </Dropdown.Item> */}
                <div class="dropdown-header text-center">
                  {userType && userType == 'Admin' ?
                    <img class="admin-img rounded-circle" src={require("../../assets/images/Mahmoud.jpg")} alt="user" />
                    : <img class="admin-img rounded-circle" src={require("../../assets/images/blank-profile.png")} alt="user" />
                  }
                  <p class="mb-1 mt-3">{userName}</p>
                  <p class="font-weight-light text-muted mb-0"><Trans>{userEmail}</Trans></p>
                </div>
                <Dropdown.Item href="!#" onClick={evt => gotoUserProfile(evt)}>
                  <p class="preview-item d-flex align-items-center m-0">
                    <i class="dropdown-item-icon icon-user text-primary mr-2"></i> <Trans>My Profile</Trans>
                    {/* <span class="badge badge-pill badge-danger ml-2">1</span> */}
                  </p>
                </Dropdown.Item>
                <Dropdown.Item href="!#" onClick={evt => gotoChangePswd(evt)}>
                  <p class="preview-item d-flex align-items-center m-0">
                    <i class="dropdown-item-icon mdi mdi-lock text-primary mr-2"></i>
                    <Trans>
                      Change Password
                    </Trans>
                    {/* <span class="badge badge-pill badge-danger ml-2">1</span> */}
                  </p>
                </Dropdown.Item>
                <Dropdown.Item href="!#" onClick={e => confirmLogout(e)}>
                  <p class="preview-item d-flex align-items-center m-0">
                    <i class="dropdown-item-icon icon-power text-primary mr-2">
                    </i>
                    <Trans>Sign Out</Trans>
                  </p>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </li>
        </ul>
        <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" onClick={toggleOffcanvas}>
          <span className="icon-menu"></span>
        </button>
      </div>
    </nav>
  );

}

export default Navbar;
