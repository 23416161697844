import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import routes from "./route";

const AuthRoute = () => {

  const getRoutes = (routes) => {
    let r = routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        console.log('COMPONENT_NAME', prop.path);
        return (
          <Route
            path={prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
    return r;
  }

  return (
    <Switch>
      {getRoutes(routes)}
      <Redirect from="*" to="/login" />
    </Switch>
  )

}

export default AuthRoute;