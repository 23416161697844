import axios from 'axios';
import { API_ENDPOINTS } from '../../appConfig';

let isLoggedIn = null;
let loginType = null;
let loginUserEmail = null;
let loginUserName = null;
let loginUserImageB64 = null;
let loginUserId = null;


export const getLoggedIn = () => {
  if (
    !isLoggedIn &&
    window.sessionStorage.getItem('validUser') === "true"
  ) {
    return true
  }
  return isLoggedIn;
}

export const setLoggedIn = (value) => {
  isLoggedIn = value;
}

const getAuthToken = () => {
  const token = window.sessionStorage.getItem("token");
  if (!token) {
    window.location = "/";
    return null;
  }
  return token;
}

export const postMethod = async (url, data, method) => {
  const token = getAuthToken();
  return axios({
    url: `${API_ENDPOINTS}${url}`,
    method,
    data,
    headers: {
      "Content-Type": "application/json",
      "Authorization": 'Bearer ' + token
    },
  });
}

export const getMethod = (url, method) => {
  const token = getAuthToken();
  return axios({
    url: `${API_ENDPOINTS}${url}`,
    method,
    // data,
    headers: {
      "Content-Type": "application/json",
      "Authorization": 'Bearer ' + token
    },
  });
}

export const getLoginType = () => {
  if (!loginType && window.sessionStorage.getItem('userType') !== "null") {
    return window.sessionStorage.getItem('userType');
  }
  return loginType;
}

export const setLoginType = (type) => {
  loginType = type;
  window.sessionStorage.setItem('userType', type);
}


export const getUserEmail = () => {
  if (!loginUserEmail && window.sessionStorage.getItem('userEmail') !== "null") {
    return window.sessionStorage.getItem('userEmail');
  }
  return loginUserEmail;
}

export const setUserEmail = (email) => {
  loginUserEmail = email.trim();
  window.sessionStorage.setItem('userEmail', loginUserEmail);
}

export const setUserName = (name) => {
  loginUserName = name;
  window.sessionStorage.setItem('userName', name);
}

export const getUserName = () => {
  if (!loginUserName && window.sessionStorage.getItem('userName') !== "null") {
    return window.sessionStorage.getItem('userName');
  }
  return loginUserName;
}

export const setUserB64 = (b64) => {
  loginUserImageB64 = b64;
  window.sessionStorage.setItem('userImg', b64);
}

export const getUserB64 = () => {
  if (!loginUserImageB64 && window.sessionStorage.getItem('userImg') !== "null") {
    return window.sessionStorage.getItem('userImg');
  }
  return loginUserImageB64;
}

export const setUserId = (id) => {
  loginUserId = id;
  window.sessionStorage.setItem('userId', id);
}

export const getUserId = () => {
  if (!loginUserId && window.sessionStorage.getItem('userId') !== "null") {
    return window.sessionStorage.getItem('userId');
  }
  return loginUserId;
}

export const loginMethod = (url, data, method) => {
  return axios({
    url: `${API_ENDPOINTS}${url}`,
    method,
    data,
    headers: {
      "Content-Type": "application/json"
    },
  });
}

export const getMethodNoToken = (url, method) => {
  // const token = getAuthToken();
  return axios({
    url: `${API_ENDPOINTS}${url}`,
    method,
    // data,
    headers: {
      "Content-Type": "application/json",
      // "Authorization": 'Bearer ' + token
    },
  });
}

export const postMethodNoToken = (url, data, method) => {
  // const token = getAuthToken();
  return axios({
    url: `${API_ENDPOINTS}${url}`,
    method,
    data,
    headers: {
      "Content-Type": "application/json",
      // "Authorization": `Bearer ${TOKEN}`
      // "Authorization": 'Bearer ' + token
    },
  });
}
export const postMethodNoTokenWithFormData = (url, data, method) => {
  // const token = getAuthToken();
  return axios({
    url: `${API_ENDPOINTS}${url}`,
    method,
    data,
    headers: {
      "Content-Type": "multipart/form-data",
      // "Authorization": `Bearer ${TOKEN}`
      // "Authorization": 'Bearer ' + token
    },
  });
}

axios.interceptors.response.use(
  (res) => {
    if (res.data) {
      res.data.responseCode = res.status;
    }
    return Promise.resolve(res);
  },
  (error) => {

    const expectedError =
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500;
    if (!expectedError) {
      console.log("Oops!", "Something went wrong!", "error");
    }
    if (error && error.response && error.response.status === 401) {
      window.window.sessionStorage.clear();
      window.location = "/";
      let ob = {
        data: {
          responseCode: '400',
          errors: [{ 'message': 'session expires' }]
        }
      };
      return Promise.resolve(ob);
    } else if (
      error && error.response &&
      (error.response.status === 400 ||
        error.response.status === 402)) {
      let ob = {
        data: {
          responseCode: error.response.status,
          errors: error.response.data.errors && error.response.data.errors.length ?
            error.response.data.errors : [{ message: 'Bad Request' }]
        }
      };
      return Promise.resolve(ob);
    } else {
      return Promise.reject(error);
    }
  });


export default {
  postMethod,
  getMethod,
  getMethodNoToken,
  postMethodNoToken,
  setLoggedIn,
  getLoggedIn,
  getLoginType,
  setLoginType,
  getUserEmail,
  setUserEmail,
  getUserB64,
  setUserB64,
  getUserName,
  setUserName,
  loginMethod,
  setUserId,
  getUserId
}