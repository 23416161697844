import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import routes from "./route";

const AdminRoutes = () => {

  const getRoutes = (routes) => {
    return routes.map((prop, key) => 
      <Route 
        path={prop.path}
        component={prop.component}
        key={key}
      />
    )
  }

  return (
    <Switch>
      {getRoutes(routes)}
      <Redirect from="*" to="/dashboard" /> 
    </Switch>
  )
}

export default AdminRoutes;
